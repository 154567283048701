import store from '@/core/services/store';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_ORION_URL;

// action types
export const AUTH_API_ORION = 'loginApiOrion';
export const SET_USER = 'setUser';

export const SET_AUTH_ORION = 'setUserOrion';
export const SET_ERROR = 'setError';
export const PURGE_AUTH_ORION = 'purgeAuthOrion';

const state = {
   errors: null,
   token: null,
   user: {}
};

const getters = {
   token: (state) => state.token
};

const actions = {
   [AUTH_API_ORION]: (context) =>
      new Promise((resolve, reject) => {
         axios.defaults.withCredentials = true;
         axios
            .post(API_URL + 'auth/plateforme', {
               prenom: store.getters.currentUser.firstname,
               nom: store.getters.currentUser.lastname,
               naissance: store.getters.currentUser.date_of_birth,
               email: store.getters.currentUser.email,
               password: store.getters.currentUser.password
            })
            .then(({ data }) => {
               context.commit(SET_AUTH_ORION, data.authorisation.token);
               context.commit(SET_USER, data.user);
               resolve(data);
            })
            .catch((response) => {
               context.commit(SET_ERROR, response);
               context.commit(PURGE_AUTH_ORION);
               reject(response);
            });
      })
};

const mutations = {
   [SET_ERROR](state, error) {
      state.errors = error;
   },
   [SET_AUTH_ORION](state, token) {
      state.token = token;
      state.errors = {};
   },
   [SET_USER](state, user) {
      state.user = user;
      state.errors = {};
   },
   [PURGE_AUTH_ORION](state) {
      state.token = {};
      state.errors = {};
   }
};

export default {
   state,
   actions,
   mutations,
   getters
};
