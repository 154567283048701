import axios from 'axios';
import authHeader from '@/core/services/store/api-orion/auth-header';

// invoice types
export const LIST_INVOICES = 'listInvoices';
export const LIST_ACTION_INVOICES = 'listActionsInvoices';
export const GET_INVOICE = 'getInvoice';

// mutation types
export const SET_ERROR = 'setError';
export const SET_INVOICE = 'setInvoice';
export const SET_INVOICES = 'setInvoices';
export const RESET_ERROR = 'resetError';

const API_URL = process.env.VUE_APP_API_ORION_URL;

const state = {
   error: null,
   invoices: [],
   ListInvoices: [],
   invoice: {}
};

const getters = {
   listInvoices: (state) => state.invoices,
   listActionsInvoices: (state) => state.invoices,
   getInvoice: (state) => state.invoice
};

const actions = {
   // LIST
   [LIST_INVOICES]: (context, params) =>
      new Promise((resolve, reject) => {
         axios
            .get(API_URL + 'factures', { headers: authHeader(), params: params.params })
            .then(({ data }) => {
               context.commit(SET_INVOICES, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      }),
   [LIST_ACTION_INVOICES]: (context, params) =>
      new Promise((resolve, reject) => {
         axios
            .get(API_URL + 'factures/societe/' + params.params.societe + '/action_client/' + params.params.id, {
               headers: authHeader()
            })
            .then(({ data }) => {
               context.commit(SET_INVOICES, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      })
};

const mutations = {
   [SET_ERROR](state, error) {
      state.error = error;
   },
   [SET_INVOICE](state, data) {
      state.invoice = data;
      state.error = null;
   },
   [SET_INVOICES](state, data) {
      state.invoices = data;
      state.error = null;
   },
   [RESET_ERROR](state) {
      state.error = null;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
};
