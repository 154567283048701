// intern types
import axios from 'axios';
import authHeader from '@/core/services/store/api-orion/auth-header';

export const GET_CLIENT_GROUPE = 'getClientGroupe';
// mutation types
export const SET_CLIENT_GROUPE = 'setClientGroupe';
export const SET_ERROR = 'setError';
export const RESET_ERROR = 'resetError';

const API_URL = process.env.VUE_APP_API_ORION_URL;

const state = {
   error: null,
   clients: []
};

const getters = {
   getClientGroupe: (state) => state.clients
};

const actions = {
   // GET
   [GET_CLIENT_GROUPE]: (context, params) =>
      new Promise((resolve, reject) => {
         axios
            .get(API_URL + 'clients/getGroupe/' + params.id, {
               headers: authHeader()
            })
            .then(({ data }) => {
               context.commit(SET_CLIENT_GROUPE, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      })
};

const mutations = {
   [SET_ERROR](state, error) {
      state.error = error;
   },
   [SET_CLIENT_GROUPE](state, data) {
      state.certificate = data;
      state.error = null;
   },
   [RESET_ERROR](state) {
      state.error = null;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
};
