import ApiService from '@/core/services/api.service';

export const SET_FORMATIONS = 'setFormations';
export const SET_FORMATIONS_USER = 'setFormationsUser';
export const GET_FORMATIONS = 'getFormations';
export const GET_FORMATION_USER = 'getFormationUser';
export const SET_ERROR = 'setError';
export const RESET_ERROR = 'resetError';

const state = {
   error: null,
   formations: [],
   formation: {}
};

const getters = {
   getFormationUser: (state) => state.formations,
   setFormationsUser: (state) => state.formation
};

const actions = {
   [SET_FORMATIONS_USER]: (context, params) =>
      new Promise((resolve, reject) => {
         ApiService.post('formation/formation/user', params)
            .then(({ data }) => {
               context.commit(SET_FORMATIONS_USER, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      }),
   [GET_FORMATION_USER]: (context, params) =>
      new Promise((resolve, reject) => {
         ApiService.query('formation/formation/user/' + params.user_id)
            .then(({ data }) => {
               context.commit(SET_FORMATIONS, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      })
};

const mutations = {
   [SET_ERROR](state, error) {
      state.error = error;
   },
   [RESET_ERROR](state) {
      state.error = null;
   },
   [SET_FORMATIONS](state, data) {
      state.formations = data;
   },
   [SET_FORMATIONS_USER](state, data) {
      state.formations = data;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
};
