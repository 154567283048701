import Vue from 'vue';
import VueExcelXlsx from 'vue-excel-xlsx';

Vue.use(VueExcelXlsx);

export default {
   name: 'vue-excel-xlsx',
   install(Vue) {
      Vue.component('vue-excel-xlsx', VueExcelXlsx);
   }
};
