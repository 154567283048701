// France
export const locale = {
   TRANSLATOR: {
      SELECT: 'choisissez votre langue'
   },
   GENERAL: {
      OK: 'OK',
      DOWNLOAD: 'Télécharger',
      SEE: 'Voir',
      FILTER: 'Filtrer',
      SHOW_FILTER: 'Afficher',
      HIDE_FILTER: 'Masquer',
      NO_RESULT: 'Pas de résultat pour cette recherche.',
      NO_DATA: "Il n'y a aucune donnée de disponible.",
      RESULTS: 'résultats',
      RESET: 'Réinitialiser',
      NOT_AVAILABLE_YET: 'Non disponible',
      ROTATE_RIGHT: 'Faire pivoter à droite',
      ROTATE_LEFT: 'Faire pivoter à gauche',
      PRINT: 'Imprimer',
      OPEN_PDF_NEW_TAB: 'Ouvrir le pdf',
      OPEN_FILE: 'Ouvrir le fichier',
      NO_PDF_AVAILABLE: 'En cours de traitement administratif. Contactez votre interlocuteur.',
      TOTAL: 'Total',
      BACK: 'Retour',
      LASTNAME: 'Nom',
      FIRSTNAME: 'Prénom',
      EXPORT: 'Exporter'
   },
   TIP: {
      ACTION: {
         TABLE: 'Les formations en orange ne sont pas validées, en rouge elles sont clôturées.',
         CALENDAR:
            "Cliquez sur un bloc pour passer à la consultation de l'action. Les formations en orange ne sont pas validées, en rouge elles sont clôturées."
      },
      INTERN: {
         TABLE: 'Double-cliquez sur une ligne pour passer à la consultation du stagiaire.'
      }
   },
   MENU: {
      HOME: 'Accueil',
      PLANNING: 'Planification',
      CLOSED_TRAININGS: 'Formations terminées',
      INTERNS: 'Stagiaires',
      INVOICES: 'Factures',
      EVACUATIONS: "Rapports d'évacuation",
      CALENDAR: 'Calendrier',
      INTERNSHIP_NOTICE: 'Avis de stage',
      STATISTIQUES: 'Statistiques'
   },
   AUTH: {
      GENERAL: {
         MY_PROFILE: 'Mon profil',
         LOGOUT: 'Déconnexion'
      },
      QUICKUSER: {
         MY_ACCOUNT: 'Mon compte',
         MY_ACCOUNT_DESC: 'Gérer mes informations personnelles',
         PLATFORM: 'Plateforme',
         GO_TO_PLATFORM: 'Aller sur la plateforme'
      }
   },
   EVACUATION: {
      DATE_EXERCICE: "Date de l'exercice",
      DATE_EXERCICE_END: "Date de fin de l'exercice",
      PLACE: "Lieu de l'exercice",
      LOCATION: 'Lieu',
      NAME: 'Nom',
      VALIDE: 'Validé',
      CONTACT_NAME: 'Contact',
      RAPPORT: 'Rapport',
      FILTER_DESC: "Parmi {number} rapport(s) d'évacuation",
      FILTER_CONTACT: "Nom de l'établissement",
      START: "Date de l'exercice entre le",
      END: 'Et le'
   },
   ACTION: {
      GENERAL: {
         TITLE: 'Formation',
         GRID: 'Affichage grille des actions',
         TABLE: 'Affichage tableau des actions',
         CALENDAR: 'Calendrier',
         HT: 'Prix HT',
         NAME_AND_CITY: 'Formation',
         ACTION_LOCATION: 'Lieu de formation',
         START: 'Débute le',
         END: 'Se termine le',
         CERTIFICATE_URL: 'Attestation',
         CERTIFICATE_URL_USERS: 'Attestations stagiaires',
         INTERNSHIP_NOTICE_URL: 'Avis de stage',
         CONVOCATIONS_URL: 'Convocations',
         ATTENDANCE_URL: 'Feuille de présence',
         CUSTOMER: 'Client',
         ACTION_STARTS_AT: 'La formation débute le',
         AND_ENDS_AT: 'et se termine le',
         INTERNS_NUMBER: 'Nombre de stagiaires',
         DATES_NUMBER: 'Nombre de dates',
         STATE: 'État',
         LEGEND: 'Légende',
         CLOSED: 'Clôturée',
         REMOTE: 'Distanciel',
         ON_PLACE: 'Présentiel',
         NOT_CLOSED: 'À confirmer',
         IS_VALID: 'Validée',
         FILTER_DESC: 'Parmi {number} actions de formation'
      },
      LABEL: {
         CUSTOMER: 'Client',
         LOCATION: 'Lieu',
         START: 'Date de début entre le',
         END: 'Et le'
      },
      INTERN: {
         TITLE: 'Tous les stagiaires',
         DESC: 'Les stagiaires participant à la formation',
         NO_INTERN: 'Pas de stagiaire pour la date sélectionnée',
         CREATE: 'Ajouter un stagiaire'
      },
      TIP: {
         CUSTOMER: "Entrez le nom d'un client",
         LOCATION: 'Entrez la ville ou le code postal',
         START: 'Sélectionnez une date de début',
         END: 'Sélectionnez une date de fin',
         NO_FILE: 'En cours de traitement administratif. Contactez votre interlocuteur.',
         NO_FILE_BEFORE_END: "Le document sera disponible à l'issue de la date de formation",
         OPEN_INVOICE: 'Ouvrir la facture'
      },
      CHART: {
         TITLE: "Nombre de formations sur l'année en cours",
         AMOUNT: 'Nombre de formations',
         THIS_MONTH: 'Ce mois-ci',
         THIS_YEAR: 'Cette année'
      },
      DATE: {
         TITLE: 'Toutes les dates',
         DESC: 'Les dates de la formation',
         DATE: 'Date',
         HOUR: 'Horaires',
         AM: 'Matin',
         PM: 'Après-midi',
         NO_DATE: 'Pas de date pour cette formation'
      }
   },
   INTERN: {
      CHART: {
         TITLE: "Stagiaires formés sur l'année en cours",
         THIS_MONTH: 'Ce mois-ci',
         THIS_YEAR: 'Cette année'
      },
      CHARTTAUXFORMATION: {
         TITLE: 'Stagiaires formés par thème'
      },
      ACTION: {
         PAGE_TITLE: 'Liste des formations de',
         TITLE: 'Formations Si2P',
         TITLE_ALT: 'Autres Formations',
         DESC: '{number} formations',
         NO_ACTION: "Ce stagiaire n'a participé à aucune formation",
         SUCCESS: 'Réussite',
         FAIL: 'Échec',
         CREATE: {
            TITLE: 'Ajouter un stagiaire',
            DESC: 'Ajouter un stagiaire à la formation',
            SUCCESS: 'Le stagiaire a bien été créé',
            FAIL: 'Une erreur est survenue lors de la création du stagiaire'
         }
      },
      FORMATION: {
         NO_FORMATION: "Ce thème n'a pas de formation associée",
         CREATE_OK: 'La formation a bien été ajoutée',
         CREATE_ERROR: "Une erreur est survenue lors de l'ajout du stagiaire"
      },
      LABEL: {
         FIRSTNAME: 'Prénom',
         LASTNAME: 'Nom'
      },
      TIP: {
         FIRSTNAME: 'Veuillez entrer le prénom du stagiaire',
         LASTNAME: 'Veuillez entrer le nom du stagiaire'
      },
      TITLE: 'Liste des stagiaires',
      DESC: '{number} stagiaires dans votre entité',
      NAME: 'Nom et prénom',
      EMAIL: 'Email',
      PHONE: 'Téléphone',
      CUSTOMER: 'Établissement',
      CREATED_AT: 'Compte créé le',
      FILTER_DESC: 'Parmi {number} stagiaires'
   },
   INVOICE: {
      ACTION: {
         TITLE: 'Factures',
         DESC: 'Les factures de cette formation'
      },
      CHART: {
         TITLE: "Factures sur l'année en cours",
         AMOUNT: 'Montant',
         THIS_MONTH: 'Ce mois-ci',
         THIS_YEAR: 'Cette année'
      },
      TITLE: 'Liste de vos factures',
      DESC: '{number} factures disponibles',
      NUMBER: 'Numéro de la facture',
      HT: 'Total H.T.',
      SETTLEMENT_DATE: 'Échéance',
      STATUT: 'Statut',
      DATE: 'Date',
      NO_INVOICE: 'Aucune facture pour cette formation pour le moment.'
   },
   _INTERN: {
      MENU: {
         ACTIONS: 'Mes formations'
      },
      ACTION: {
         GENERAL: {
            TITLE: 'Mes formations',
            LIST_TITLE: 'Formations Si2P',
            LIST_DESC: 'Vous avez participé à {number} formations',
            NO_ACTION: "Vous n'avez participé à aucune formation",
            VIEW_TITLE: 'Mon attestation',
            CERTIFICATE_URL: 'Mon attestation de formation',
            DOWNLOAD_TITLE: 'Télécharger mon attestation'
         }
      }
   },
   INTERNSHIP_NOTICE: {
      MENU: {
         TITLE: 'Avis de stage'
      },
      GENERAL: {
         PRODUCT_CODE: 'Formation',
         CUSTOMER: 'Établissement',
         AVERAGE_NOTE: 'Note moyenne',
         DATE: 'Date de formation',
         NUMBER_OF_TRAINEES: 'Nombre de stagiaires',
         TAUX: 'Taux de satisfaction',
         SHOW_NOTICES: 'Voir les avis'
      },
      DETAILS: {
         INTERN_TABLE_TITLE: 'Liste des stagiaires ayant laissé un avis',
         QUESTION: 'Question',
         AUTHOR: 'Avis laissé par ',
         SHOW: "Voir l'avis de stage",
         FORMER_NAME: 'Formateur'
      }
   },
   STATISTIQUES: {
      GENERAL: {
         THEME: 'Thème de formation',
         CUSTOMER: 'Etablissement',
         TAUX_REQUIS: "% d'effectif à former",
         SEUIL_MIN: 'Effectif minimum à former',
         OBJECTIF_EFFECTIF: 'Effectif formé attendu',
         EFFECTIF_FORME: 'Effectif formé',
         EFFECTIF_REEL: 'Effectif',
         TAUX_ATTEINT: 'Taux atteint',
         STATUT: 'Statut',
         ACTION: 'Action'
      },
      DETAILS: {
         TITLE: "Détails de l'effectif formé pour le thème ",
         LASTNAME: 'Nom',
         FIRSTNAME: 'Prénom',
         EMAIL: 'Email',
         DATE_OBTAINED: "Date d'obtention",
         DATE_EXPIRY: "Date d'expiration",
         SOURCE: 'Présentiel / Distanciel'
      }
   }
};
