// intern types
import axios from 'axios';
import authHeader from '@/core/services/store/api-orion/auth-header';

export const GET_CERTIFICATE = 'getCertificate';
export const GET_INTERN_ORION = 'getInternOrion';
// mutation types
export const SET_ERROR = 'setError';
export const SET_CERTIFICATE = 'setCertificate';
export const SET_INTERN_ORION = 'setInternOrion';
export const RESET_ERROR = 'resetError';

const API_URL = process.env.VUE_APP_API_ORION_URL;

const state = {
   error: null,
   certificate: {},
   internOrion: {}
};

const getters = {
   getCertificate: (state) => state.certificate,
   getInternOrion: (state) => state.internOrion
};

const actions = {
   // GET
   [GET_CERTIFICATE]: (context, params) =>
      new Promise((resolve, reject) => {
         axios
            .get(API_URL + 'certificate/' + params.params.actionId + '/' + params.params.societeId + '/' + params.params.stagiaireId, {
               headers: authHeader()
            })
            .then(({ data }) => {
               context.commit(SET_CERTIFICATE, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      }),
   [GET_INTERN_ORION]: (context, params) =>
      new Promise((resolve, reject) => {
         axios
            .get(API_URL + 'stagiaire/' + params.id, {
               headers: authHeader()
            })
            .then(({ data }) => {
               context.commit(SET_INTERN_ORION, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      })
};

const mutations = {
   [SET_ERROR](state, error) {
      state.error = error;
   },
   [SET_CERTIFICATE](state, data) {
      state.certificate = data;
      state.error = null;
   },
   [SET_INTERN_ORION](state, data) {
      state.internOrion = data;
      state.error = null;
   },
   [RESET_ERROR](state) {
      state.error = null;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
};
