import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment';
import i18nService from '@/core/services/i18n.service.js';

const language = i18nService.getActiveLanguage();
require(`moment/locale/${language}`);

Vue.use(VueMoment, {
   moment
});
