import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from '@/core/services/store';
import { REFRESH_TOKEN } from '@/core/services/store/api/auth';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
   init() {
      Vue.use(VueAxios, axios);
      Vue.axios.defaults.withCredentials = true;
      Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
      Vue.axios.interceptors.response.use(
         (response) => {
            if (response.config.url !== 'refresh-token') {
               store.dispatch(REFRESH_TOKEN);
            }
            return response;
         },
         (error) => {
            if (error.response && error.response.status && error.response.status !== 401 && error.response.config.url !== 'refresh-token') {
               store.dispatch(REFRESH_TOKEN);
            }
            return Promise.reject(error);
         }
      );
   },

   query: (resource, params) => Vue.axios.get(resource, params),

   /**
    * Send the GET HTTP request
    * @param resource
    * @param slug
    * @returns {*}
    */
   get: (resource, slug = '') => Vue.axios.get(`${resource}/${slug}`),

   /**
    * Set the POST HTTP request
    * @param resource
    * @param params
    * @returns {*}
    */
   post: (resource, params) => Vue.axios.post(`${resource}`, params),

   /**
    * Send the UPDATE HTTP request
    * @param resource
    * @param slug
    * @param params
    * @returns {IDBRequest<IDBValidKey> | Promise<void>}
    */
   update: (resource, slug, params) => Vue.axios.put(`${resource}/${slug}`, params),

   /**
    * Send the PUT HTTP request
    * @param resource
    * @param params
    * @returns {IDBRequest<IDBValidKey> | Promise<void>}
    */
   put: (resource, params) => Vue.axios.put(`${resource}`, params),

   /**
    * Send the DELETE HTTP request
    * @param resource
    * @returns {*}
    */
   delete: (resource) => Vue.axios.delete(resource)
};

export default ApiService;
