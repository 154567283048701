export const SET_PAGETITLE = 'setPageTitle';

export default {
   state: {
      pageTitle: ''
   },
   getters: {
      pageTitle: (state) => state.pageTitle
   },
   actions: {
      [SET_PAGETITLE](state, payload) {
         state.commit(SET_PAGETITLE, payload);
      }
   },
   mutations: {
      [SET_PAGETITLE](state, payload) {
         state.pageTitle = payload;
      }
   }
};
