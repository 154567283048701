import Vue from 'vue';
import Vuex from 'vuex';
/* import createPersistedState from 'vuex-persistedstate' */

import auth from './api/auth';
import authOrion from '@/core/services/store/api-orion/auth';
import action from './api-orion/action';
import intern from './api/intern';
import internOrion from './api-orion/intern';
import evacuation from './api-orion/evacuation';
import customer from '@/core/services/store/api/customer';
import date from './api/date';
import invoice from '@/core/services/store/api-orion/invoice';
import htmlClass from './htmlclass.module';
import config from './config.module';
import pagetitle from './pagetitle.module';
import tableSource from '@/core/services/store/api/tableSource';
import rights from '@/core/services/store/api/rights';
import formation from '@/core/services/store/api/formation';
import theme from '@/core/services/store/api/theme';
import client from '@/core/services/store/api-orion/client';
import clientProduit from '@/core/services/store/api-orion/clientsProduits';
import avisStagiaires from '@/core/services/store/api-orion/avisStagiaires';
import statistique from '@/core/services/store/api/statistique';

Vue.use(Vuex);

export default new Vuex.Store({
   /* plugins: [
        createPersistedState({
            paths: ['auth']
        })
    ], */
   modules: {
      auth,
      authOrion,
      action,
      intern,
      internOrion,
      customer,
      invoice,
      tableSource,
      evacuation,
      date,
      htmlClass,
      config,
      pagetitle,
      rights,
      formation,
      theme,
      client,
      clientProduit,
      avisStagiaires,
      statistique
   }
});
