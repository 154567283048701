import store from '@/core/services/store/index';
export default function intern({ next, to }) {
   if (to.name === 'internship-notice.list') {
      if (
         (store.getters.getRole.name === 'Gestionnaire' && store.getters.currentUser.avisDeStage === 0) ||
         store.getters.getRole.name === 'Stagiaire'
      ) {
         return next({ name: 'home' });
      }
   }

   if (to.name === 'statistiques' || to.name === 'statistiques.trained') {
      if (
         (store.getters.getRole.name === 'Gestionnaire' && store.getters.currentUser.tauxEtSeuil === 0) ||
         store.getters.getRole.name === 'Stagiaire'
      ) {
         return next({ name: 'home' });
      }
   }
}
