// intern types
import axios from 'axios';
import authHeader from '@/core/services/store/api-orion/auth-header';

export const GET_EVAC_CLIENT = 'getEvacClient';
export const SET_EVAC_CLIENT = 'setEvacClient';
export const SET_ERROR = 'setError';
export const RESET_ERROR = 'resetError';

const API_URL = process.env.VUE_APP_API_ORION_URL;

const state = {
   error: null,
   evacuations: []
};

const getters = {
   getEvacClient: (state) => state.evacuations
};

const actions = {
   // GET
   [GET_EVAC_CLIENT]: (context, params) =>
      new Promise((resolve, reject) => {
         axios
            .get(API_URL + 'evacuations/client', {
               headers: authHeader(),
               params: params.form
            })
            .then(({ data }) => {
               context.commit(SET_EVAC_CLIENT, data);
               resolve(data);
            })
            .catch(({ response }) => {
               context.commit(SET_ERROR, response.data);
               reject(response);
            });
      })
};

const mutations = {
   [SET_ERROR](state, error) {
      state.error = error;
   },
   [SET_EVAC_CLIENT](state, data) {
      state.evacuations = data;
      state.error = null;
   },
   [RESET_ERROR](state) {
      state.error = null;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
};
