import store from '@/core/services/store';

export default function authHeader() {
   const token = store.getters.token;
   if (token) {
      return { Authorization: 'Bearer ' + token };
   } else {
      return {};
   }
}
